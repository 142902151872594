import React, { useMemo, useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Grid,
  Link,
  Divider,
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { useSelector, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { updateProject } from "../reducers";
import ProjectDetails from "../components/ProjectDetails";
import FileTab from "../components/FileTab";
import LogTab from "./LogTab";
import { createTheme, ThemeProvider } from "@mui/material/styles";

dayjs.extend(utc);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectDetailsTabs = ({ projectData }) => {
  const dispatchState = useDispatch();
  const project = useSelector((state) => state.project);
  const [employees, setEmployees] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [branchAddresses, setBranchAddresses] = useState([]);
  const [branchAddressId, setBranchAddressId] = useState("");
  const [measuredById, setMeasuredById] = useState("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dateTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiInputLabel: {
            styleOverrides: {
              root: {
                marginTop: project?.start_date ? "0px" : "-7px",
              },
            },
          },
        },
      }),
    [project],
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBranchBlur = () => {
    dispatchState(
      updateProject({
        ...project,
        genesis_branch_address: branchAddresses.filter(
          (branchAddress) => branchAddress.id === branchAddressId,
        )[0],
      }),
    );
  };

  const handleMeasuredByBlur = () => {
    dispatchState(
      updateProject({
        ...project,
        measured_by_employee: employees.filter(
          (employee) => employee.id === measuredById,
        )[0],
      }),
    );
  };

  useEffect(() => {
    setBranchAddressId(project?.genesis_branch_address?.id || "");
    setMeasuredById(project?.measured_by_employee?.id || "");
  }, [project]);

  useEffect(() => {
    if (tabValue !== 0) {
      // If project info tab is not selected
      return;
    }
    decoratedFetch("/list_employees")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setEmployees(data);
        } else {
          setEmployees([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        // Set an empty array in case of an error
        setEmployees([]);
      });
    decoratedFetch("/list_branch_addresses")
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setBranchAddresses(data);
        } else {
          setBranchAddresses([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setBranchAddresses([]);
      });
  }, [tabValue]);

  const updateProjectSR = (event) => {
    const newSalesRepId = event.target.value;
    if (newSalesRepId === project.sales_employee.id) {
      return;
    }
    const updatedProject = {
      ...project,
      sales_employee: employees.find(
        (employee) => employee.id === newSalesRepId,
      ),
    };
    dispatchState(updateProject(updatedProject));
  };

  const updateProjectPM = (event) => {
    const newPMId = event.target.value;
    if (newPMId === project.pm_employee.id) {
      return;
    }
    const updatedProject = {
      ...project,
      pm_employee: employees.find((employee) => employee.id === newPMId),
    };
    dispatchState(updateProject(updatedProject));
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {isSmallScreen ? (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="project-details-tab"
            >
              <Tab label="Project Details" {...a11yProps(0)} />
              <Tab label="Log" {...a11yProps(2)} />
              <Tab label="Files" {...a11yProps(1)} />
            </Tabs>
          ) : (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="fullWidth"
              aria-label="project-details-tab"
            >
              <Tab label="Log" {...a11yProps(2)} />
              <Tab label="Files" {...a11yProps(1)} />
              <Tab label="Project Details" {...a11yProps(0)} />
            </Tabs>
          )}
        </Box>
        <TabPanel value={tabValue} index={isSmallScreen ? 0 : 2}>
          <ProjectDetails />
        </TabPanel>
        <TabPanel value={tabValue} index={isSmallScreen ? 2 : 1}>
          <FileTab value={tabValue} index={isSmallScreen ? 2 : 1} />
        </TabPanel>
        <TabPanel value={tabValue} index={isSmallScreen ? 1 : 0}>
          <LogTab
            value={tabValue}
            index={isSmallScreen ? 1 : 0}
            projectName={project.name}
            projectCustomId={project.custom_id}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default ProjectDetailsTabs;
