import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { SUPPORT_EMAIL } from "../constants";

const ForgotYourPasswordPage = () => {
  const theme = useTheme();
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const [textFieldMessage, setTextFieldMessage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const isValidEmail = (email) => {
    return email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setAlertMessage("");
    setTextFieldMessage("");
    setSuccess(false);

    if (!email) {
      setTextFieldMessage("Required");
      return;
    }

    if (!isValidEmail(email)) {
      setTextFieldMessage("Invalid email format");
      return;
    }

    setSubmitDisabled(true);

    decoratedFetch(
      "/send_password_reset_email",
      {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      },
      /*no_refresh=*/ true,
    )
      .then((res) => {
        switch (res.status) {
          case 200:
            setAlertSeverity("success");
            setAlertMessage("Password reset email sent");
            setSubmitDisabled(false);
            return;
          case 400:
            setAlertMessage("Missing email address");
            break;
          case 500:
            setAlertMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setAlertMessage(
              `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .catch((error) => {
        setSubmitDisabled(false);
        setAlertSeverity("error");
        if (error.message !== "Error message generated") {
          setAlertMessage(
            `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
          );
        }
      });
  };

  return (
    <Box
      sx={{
        display: "grid",
        marginTop: "30px",
      }}
    >
      {alertMessage ? (
        <Box
          sx={{
            width: "60%",
            mx: "auto",
          }}
        >
          <Collapse in={alertMessage}>
            <Alert
              variant="outlined"
              severity={alertSeverity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Box>
      ) : null}
      <Box
        sx={{
          mx: "auto",
          marginTop: "60px",
          "& img": {
            width: {
              xs: "180px",
              sm: "295px",
            },
          },
        }}
      >
        <img src="/genesis_logo.png" alt="Genesis Floor Covering Inc. logo" />
      </Box>
      <Box
        sx={{
          mx: "auto",
          marginTop: {
            xs: "40px",
            sm: "60px",
          },
        }}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          sx={{
            textAlign: "center",
            [theme.breakpoints.up("xs")]: {
              typography: "h6",
              component: "h6",
              size: "medium",
            },
            [theme.breakpoints.up("sm")]: {
              typography: "h4",
              component: "h4",
              size: "medium",
            },
          }}
        >
          Forgot your password?
        </Typography>
      </Box>
      <Box
        sx={{
          mx: "auto",
          textAlign: "center",
          mt: {
            xs: "15px",
            sm: "25px",
          },
          mb: "5px",
          maxWidth: {
            xs: "75%",
            sm: "60%",
            md: "50%",
            lg: "35%",
            xl: "25%",
          },
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "14px",
              sm: "18px",
            },
          }}
        >
          {
            "Enter your email address and we'll send you an email to reset your password."
          }
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mx: "auto",
          width: {
            xs: "300px",
            sm: "400px",
          },
          marginTop: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="email"
              label="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={textFieldMessage}
              helperText={textFieldMessage}
              InputLabelProps={{ shrink: email.length > 0 }}
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={submitDisabled}
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ForgotYourPasswordPage;
