import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { decoratedFetch } from "../req_utils";
import { SUPPORT_EMAIL } from "../constants";
import PhoneNumberFormat from "../components/PhoneNumberFormat";

const SignUpPage = () => {
  const theme = useTheme();
  const dispatchState = useDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  const signup_token = new URLSearchParams(loc.search).get("t");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmedPassword: "",
    phone: "",
  });
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emptyPasswordError, setEmptyPasswordError] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(false);
  const [confirmedPasswordError, setConfirmedPasswordError] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const [uncollapsedSignupErrorMessage, setUncollapsedSignupErrorMessage] =
    useState(true);
  const [signupButtonDisabled, setSignupButtonDisabled] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return phoneNumber;

    // Remove non-numeric characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    // Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e.target.firstName.value) {
      setFirstNameError(true);
      return;
    }
    setFirstNameError(false);
    if (!e.target.lastName.value) {
      setLastNameError(true);
      return;
    }
    setLastNameError(false);
    if (!e.target.password.value) {
      setEmptyPasswordError(true);
      return;
    }
    setPhoneError(false);
    if (!e.target.phone.value) {
      setPhoneError(true);
      return;
    }
    setEmptyPasswordError(false);
    if (!e.target.confirmedPassword.value) {
      setConfirmedPasswordError(true);
      return;
    }
    setConfirmedPasswordError(false);
    if (e.target.password.value !== e.target.confirmedPassword.value) {
      setPasswordMismatchError(true);
      return;
    }

    setPasswordMismatchError(false);
    setSignupButtonDisabled(true);

    decoratedFetch(
      "/sign_up",
      {
        method: "POST",
        body: JSON.stringify({
          first_name: e.target.firstName.value,
          last_name: e.target.lastName.value,
          password: e.target.password.value,
          phone: formatPhoneNumber(e.target.phone.value),
          signup_token: signup_token,
        }),
      },
      /*no_refresh=*/ true,
    )
      .then((res) => {
        switch (res.status) {
          case 200:
          case 401:
          case 403:
            return res.json();
          case 400:
            setSignupErrorMessage("Incomplete signup form");
            break;
          case 500:
            setSignupErrorMessage(
              `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
            break;
          default:
            setSignupErrorMessage(
              `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
            );
        }
        throw new Error("Error message generated");
      })
      .then((data) => {
        if (data.msg !== "success") {
          setSignupErrorMessage(data.msg);
          throw new Error("Error message generated");
        }
        decoratedFetch("/look_up_employee")
          .then((response) => {
            switch (response.status) {
              case 200:
                return response.json();
              case 404:
                setSignupErrorMessage(
                  `User does not exist. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              case 401:
                setSignupErrorMessage(
                  `User unauthenticated. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              case 500:
                console.log(
                  `Internal server error. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
                break;
              default:
                setSignupErrorMessage(
                  `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
                );
            }
            throw new Error("Error message generated");
          })
          .then((data) => {
            dispatchState({
              type: "mark_as_authenticated",
              employee: data,
              selectedPage: "Dashboard",
            });
            navigate("/");
          })
          .catch((error) => {
            setSignupButtonDisabled(false);
            if (error.message !== "Error message generated") {
              setSignupErrorMessage(
                `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
              );
            }
            setUncollapsedSignupErrorMessage(true);
          });
      })
      .catch((error) => {
        setSignupButtonDisabled(false);
        if (error.message !== "Error message generated") {
          setSignupErrorMessage(
            `Unknown error occurred. If this persists, please contact ${SUPPORT_EMAIL}`,
          );
        }
        setUncollapsedSignupErrorMessage(true);
      });
  };

  return (
    <Box
      sx={{
        display: "grid",
        marginTop: "30px",
      }}
    >
      {signupErrorMessage ? (
        <Box
          sx={{
            width: "60%",
            mx: "auto",
          }}
        >
          <Collapse in={uncollapsedSignupErrorMessage}>
            <Alert
              variant="outlined"
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setUncollapsedSignupErrorMessage(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {signupErrorMessage}
            </Alert>
          </Collapse>
        </Box>
      ) : null}
      <Box
        sx={{
          mx: "auto",
          marginTop: "60px",
          "& img": {
            width: {
              xs: "180px",
              sm: "295px",
            },
          },
        }}
      >
        <img src="/genesis_logo.png" alt="Genesis Floor Covering Inc. logo" />
      </Box>
      <Box
        sx={{
          mx: "auto",
          marginTop: {
            xs: "40px",
            sm: "60px",
          },
        }}
      >
        <Typography
          style={{ fontWeight: "bold" }}
          sx={{
            textAlign: "center",
            [theme.breakpoints.up("xs")]: {
              typography: "h6",
              component: "h6",
              size: "medium",
            },
            [theme.breakpoints.up("sm")]: {
              typography: "h4",
              component: "h4",
              size: "medium",
            },
          }}
        >
          Create an account
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mx: "auto",
          width: {
            xs: "300px",
            sm: "400px",
          },
          marginTop: {
            xs: "15px",
            sm: "30px",
          },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="fname"
              name="firstName"
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
              error={firstNameError}
              helperText={firstNameError ? "First name must be non-empty" : ""}
              InputLabelProps={{ shrink: formData?.firstName?.length > 0 }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lname"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
              error={lastNameError}
              helperText={lastNameError ? "Last name must be non-empty" : ""}
              InputLabelProps={{ shrink: formData?.lastName?.length > 0 }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="phone"
              label="Phone Number"
              type="phone"
              id="phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              error={phoneError}
              helperText={phoneError ? "Phone number must be non-empty" : ""}
              InputProps={{
                inputComponent: PhoneNumberFormat,
              }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              error={emptyPasswordError}
              helperText={
                emptyPasswordError ? "Password must be non-empty" : ""
              }
              InputLabelProps={{ shrink: formData?.password?.length > 0 }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="confirmedPassword"
              label="Confirm Password"
              type="password"
              id="confirmedPassword"
              value={formData.confirmedPassword}
              onChange={(e) =>
                setFormData({ ...formData, confirmedPassword: e.target.value })
              }
              error={confirmedPasswordError || passwordMismatchError}
              helperText={
                confirmedPasswordError
                  ? "Confirmed password must be non-empty"
                  : passwordMismatchError
                    ? "Passwords must match"
                    : ""
              }
              InputLabelProps={{
                shrink: formData?.confirmedPassword?.length > 0,
              }}
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={signupButtonDisabled}
          sx={{ mt: 3, mb: 2 }}
        >
          Sign Up
        </Button>
      </Box>
    </Box>
  );
};

export default SignUpPage;
