import React from "react";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Switch from "@mui/material/Switch";
import axios from "axios"; // If you're using axios for HTTP requests
import { decoratedFetch } from "../req_utils";

const UsersTable = ({ employees, employeesLoading, onStatusChange }) => {
  const employee_id = useSelector((state) => state?.employee?.id);

  const handleSwitchChange = async (employee, checked) => {
    const api = `/update_employee/${employee.id}`;
    decoratedFetch(api, {
      method: "PUT",
      body: JSON.stringify({
        enabled: checked,
      }),
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          onStatusChange(employee.id, checked); // Update the parent component's state
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        console.error(`Error adding log item`);
      });
  };

  const columns = [
    { field: "first_name", headerName: "First name", editable: false, flex: 1 },
    { field: "last_name", headerName: "Last name", editable: false, flex: 1 },
    { field: "email", headerName: "Email", editable: false, flex: 1 },
    { field: "cell_no", headerName: "Phone", editable: false, flex: 1 },
    { field: "employee_type", headerName: "Role", flex: 1 },
    {
      field: "enabled",
      headerName: "Status",
      width: 90,
      renderCell: (params) => (
        <Switch
          checked={params.value}
          disabled={params?.row?.id === employee_id}
          onChange={(event) =>
            handleSwitchChange(params.row, event.target.checked)
          }
          color="primary"
        />
      ),
      type: "boolean", // Ensuring sorting/filtering considers it as a boolean
    },
  ];

  const rows = employees.map((employee, index) => ({
    id: employee.id,
    first_name: employee.first_name,
    last_name: employee.last_name,
    email: employee.email,
    cell_no: employee.cell_no,
    employee_type: employee.employee_type,
    enabled: employee.enabled,
  }));

  return (
    <div style={{ height: 610, width: "100%", paddingTop: 10 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        onCellClick={(params, e) => e.stopPropagation()}
        loading={employeesLoading}
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
            {
              outline: "none",
            },
        }}
      />
    </div>
  );
};

export default UsersTable;
