import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import ContactsTab from "./ContactsTab";
import AddressesTab from "./AddressesTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ClientInformationTabs = ({ account_id, canDelete, border = false }) => {
  const [value, setValue] = useState(0);
  const project = useSelector((state) => state.project);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", height: 458 }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            borderTop: border ? "1px solid #c4c4c4" : "none",
            borderRight: border ? "1px solid #c4c4c4" : "none",
            borderLeft: border ? "1px solid #c4c4c4" : "none",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="project-details-tab"
          >
            <Tab
              sx={{ backgroundColor: "white" }}
              label="Contacts"
              {...a11yProps(0)}
            />
            <Tab
              sx={{ backgroundColor: "white" }}
              label="Addresses"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ContactsTab
            value={value}
            index={0}
            account_id={account_id}
            canDelete={canDelete}
            border={border}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddressesTab
            value={value}
            index={1}
            account_id={account_id}
            canDelete={canDelete}
            border={border}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default ClientInformationTabs;
