import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Snackbar, Grid, IconButton, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CreateContactModal } from "../components/modals/CreateContactModal";
import { decoratedFetch } from "../req_utils";
import { useParams } from "react-router-dom";
import { refreshProposalCustomers } from "../reducers";
import { GENESIS_LOGO_COLOR } from "../constants";

const columns = [
  { field: "contactType", headerName: "Contact Type", flex: 1 },
  { field: "firstName", headerName: "First Name", flex: 1 },
  { field: "lastName", headerName: "Last Name", flex: 1 },
  { field: "cellNumber", headerName: "Cell Number", flex: 1 },
  { field: "email", headerName: "Email", flex: 1 },
];

function ContactsTab({ value, index, account_id, canDelete, border = false }) {
  const dispatchState = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [isNew, setNewValue] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const { project_id } = useParams();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [contactsLoading, setContactsLoading] = useState(false);
  const handleOpen = () => {
    setNewValue(true);
    setOpen(true);
  };
  const handleRowClick = (params) => {
    setSelectedContact(contacts.find((contact) => contact.id === params.id));
    setNewValue(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedContact(null);
  };
  const [trigger, setTrigger] = useState(false);

  const triggerParentUpdate = () => {
    setTrigger((prev) => !prev); // Toggle the state to force re-render
    dispatchState(refreshProposalCustomers(true));
  };

  useEffect(() => {
    if (value !== index) {
      return;
    }
    setContactsLoading(true);
    if (!account_id) {
      return;
    }
    let url = `/list_contacts?account_id=${account_id}`;
    if (project_id) {
      url = `${url}&project_id=${project_id}`;
    }
    decoratedFetch(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Unknown error");
        }
        return response.json();
      })
      .then((data) => {
        setContacts(
          data.map((item) => {
            return {
              ...item,
              id: item.id,
              contactType: item.contact_type,
              firstName: item.first_name,
              lastName: item.last_name,
              cellNumber: item.cell_no,
              email: item.email,
            };
          }),
        );
        setContactsLoading(false);
      })
      .catch((error) => {
        setContactsLoading(false);
        console.error(`Error listing contacts: ${error}`);
        setContacts([]);
      });
  }, [value, account_id, project_id, trigger]);

  return (
    <>
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            backgroundColor: GENESIS_LOGO_COLOR,
            minWidth: 0,
          },
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <CreateContactModal
        isOpen={open}
        handleClose={handleClose}
        triggerParentUpdate={triggerParentUpdate}
        contact={selectedContact}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        account_id={account_id}
        canDelete={canDelete}
      />
      <div
        style={{
          width: "100%",
          padding: 0,
          borderBottom: border ? "1px solid #c4c4c4" : "none",
          borderRight: border ? "1px solid #c4c4c4" : "none",
          borderLeft: border ? "1px solid #c4c4c4" : "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "8px",
            backgroundColor: "white",
          }}
        >
          <IconButton
            aria-label="add"
            onClick={handleOpen}
            style={{ marginLeft: "auto" }}
          >
            <AddIcon />
          </IconButton>
        </div>
        <Paper elevation={0} sx={{ height: "508px", width: "100%" }}>
          <DataGrid
            rows={contacts}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={50}
            onRowClick={handleRowClick}
            disableMultipleSelection
            loading={contactsLoading}
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cellCheckbox:focus, & .MuiDataGrid-cellCheckbox:focus-within":
                {
                  outline: "none",
                },
              borderLeft: border ? "#c4c4c4" : null,
              borderRight: border ? "#c4c4c4" : null,
              borderBottom: border ? "#c4c4c4" : null,
            }}
            localeText={{ noRowsLabel: "No contacts" }}
          />
        </Paper>
      </div>
    </>
  );
}

export default ContactsTab;
